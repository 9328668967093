<template>
  <v-row class="reserveconffields">
    <v-col cols="5" class="py-0">
      <small class="font-weight-6">{{ fieldname }}</small>
    </v-col>
    <v-col cols="7" class="p-0">
      <small
        class="font-weight-6"
        style="word-break: break-word; white-space: pre"
        >{{ fieldval }}</small
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ReseveConfirmationFields',
  props: ['fieldname', 'fieldval'],
};
</script>

<style>
.reserveconffields small {
  font-size: 12px;
}
@media screen and (max-width: 375px) {
  .reserveconffields small {
    font-size: 11px;
  }
}
@media screen and (max-width: 360px) {
  .reserveconffields small {
    font-size: 10px;
  }
}
</style>

<template>
  <section>
    <Appbar :menu="true"></Appbar>
    <v-main>
      <v-container v-if="reservationDetails">
        <v-row>
          <v-col cols="12" class="text-center">
            <img src="@/assets/images/successavatar.png" class="w-25" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-center py-0">
            <h4 class="font-weight-6">Thank You!</h4>
          </v-col>
          <v-col cols="12" class="text-center py-0">
            <h5 class="font-weight-6">Your Reservation Has Been Made.</h5>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" class="text-center py-0">
            <h6 class="font-weight-6">
              Reservation ID : {{ reservationDetails.reserveid }}
            </h6>
          </v-col>
        </v-row>
        <br />
        <div class="px-7">
          <v-row>
            <v-col cols="8" class="py-0">
              <p class="subtitle-2 font-weight-6">Reservation Details</p>
            </v-col>
          </v-row>
          <ReseveConfirmationFields
            fieldname="Name:"
            :fieldval="reservationDetails.guestName"
          />
          <ReseveConfirmationFields
            fieldname="Email Address:"
            :fieldval="reservationDetails.guestEmail"
          />
          <ReseveConfirmationFields
            fieldname="Contact No:"
            :fieldval="reservationDetails.guestPhoneNo"
          />
          <ReseveConfirmationFields
            fieldname="No. of Pax:"
            :fieldval="reservationDetails.noOfGuest"
          />
          <ReseveConfirmationFields
            fieldname="Reserved Date:"
            :fieldval="reservationDetails.reservedDate"
          />
          <ReseveConfirmationFields
            fieldname="Reserved Time:"
            :fieldval="reservationDetails.reservedTime"
          />
          <ReseveConfirmationFields
            v-if="reservationDetails.notes"
            fieldname="Special Instructions:"
            :fieldval="reservationDetails.notes"
          />
        </div>
        <br />
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              @click="$router.push({ name: 'home' }).catch(() => {})"
              color="primary"
              class="text-capitalize"
            >
              Services
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </section>
</template>

<script>
import Appbar from '@/components/partials/Appbar';
import ReseveConfirmationFields from '@/components/Reservation/ReseveConfirmationFields';
export default {
  props: ['reservationDetails'],
  name: 'ReservationConfirm',
  components: { Appbar, ReseveConfirmationFields },
  created() {
    if (!this.reservationDetails) {
      this.$router.push({ name: 'reserve' });
    }
  },
};
</script>

<style scoped></style>

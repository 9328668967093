<template>
  <v-app-bar
    app
    fixed
    dense
    light
    color="#ffffff"
    style="z-index: 3;"
  > 
    <router-link
      :to="{ name: 'home' }"
      style="color: black !important"
      class="d-flex justify-content-center align-items-center"
    >
      <v-toolbar-title>
        <v-img
          class="mr-2"
          :src="getMerchantLogo()"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
      </v-toolbar-title>
      <div class="text-uppercase">
        <small class="font-weight-6">{{ profile.restaurant_name }}</small>
      </div></router-link
    >

    <v-icon
      @click="dialogInfo = !dialogInfo"
      style="background: #cbcbcb"
      class="mx-2 text-white rounded-circle"
      small
    >
      mdi-information-variant
    </v-icon>
    <v-icon @click="dialogShare = !dialogShare" color="#cbcbcb"
      >mdi-share-variant</v-icon
    >
    <v-spacer></v-spacer>
    <v-icon v-if="close" color="#cbcbcb" @click="$emit('close')"
      >mdi-close</v-icon
    >
    <v-app-bar-nav-icon v-if="menu" @click.stop="$emit('open-drawer')"></v-app-bar-nav-icon>
    <v-dialog v-model="dialogShare" max-width="290">
      <v-card>
        <v-card-title class="headline"> Share </v-card-title>
        <v-card-text>Let your friends know about us by sharing</v-card-text>
        <v-card-text>
          <div class="d-flex justify-content-around">
            <v-icon color="#3b5998" @click="ShareThisonFB">mdi-facebook</v-icon>
            <v-icon color="#1da1f2" @click="ShareThisonTwitter">mdi-twitter</v-icon>
            <v-icon color="#25d366" @click="ShareThisonWhatsapp">mdi-whatsapp</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" small plain text @click="dialogShare = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInfo" width="400px" class="h-100">
      <v-card class="py-3">
        <v-card-text class="text-dark">
          <div class="mb-3">
            <p class="text-overline">Location</p>
            <p class="text-caption font-weight-6">{{ profile.street }}</p>
          </div>
          <div class="mb-3">
            <p class="text-overline">Contact No.</p>
            <p class="text-caption font-weight-6">{{ profile.restaurant_phone }}</p>
          </div>

          <div>
            <p class="text-overline">Operating Hours</p>
            <div
              v-for="ophour in businessHours"
              :key="ophour.dayName"
              class="d-flex justify-space-between w-60 infotxt"
            >
              <p class="text-caption text-capitalize font-weight-6">{{ ophour.dayName }}</p>
              <p class="text-caption font-weight-6">{{ formatTime(ophour.open_am_at || ophour.open_pm_at) }} - {{ formatTime(ophour.close_am_at || ophour.close_pm_at) }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: ['menu', 'close', 'back'],
  components: {
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      dialogShare: false,
      dialogInfo: false,
      opdata: null,
      hostname: location.origin,
      whatsappmsg: 'Visit EatMol to avail coupons',
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      businessHours: (state) => state.businessHours,
    }),
  },
  methods: {
    ...mapActions('merchant', [
      'getMerchantBusinessHours',
    ]),
    ShareThisonFB() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.hostname}`,
        '_blank'
      );
    },
    ShareThisonTwitter() {
      window.open(`https://twitter.com/intent/tweet`, '_blank');
    },
    ShareThisonWhatsapp() {
      window.open(
        `https://api.whatsapp.com/send?text=${this.whatsappmsg} ${this.hostname}`,
        '_blank'
      );
    },
    getMerchantLogo() {
      return this.profile.logo_s3
        ? `${this.cdnUrl}/${this.profile.logo_s3}`
        : `${this.legacyCdnUrl}/upload/${this.profile.logo}`;
    },
    formatTime(t) {
      return dayjs(t, 'HH:mm:ss').format('HH:mm a');
    },
  },
  async mounted() {
    // Load Business Hours
    if (!this.businessHours) {
      await this.getMerchantBusinessHours({
        merchantId: this.profile.merchant_id,
        service: this.service,
        stateId: this.profile.state_id,
      });
    }
  }
};
</script>

<style>
.bg-green-light {
  background: #c4eec1 !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}
.w-60 {
  width: 60%;
}
.infotitle {
  font-size: 14px;
}
.infotxt {
  font-size: 12px;
}

@media screen and (max-width: 320px) {
  .infotitle {
    font-size: 12px;
  }
  .infotxt {
    font-size: 10px;
  }
}
</style>
